import React from "react";
import { Route, Routes } from "react-router-dom";
import { About } from "./components/About";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { Home } from "./components/Home";
import { Impressum } from "./components/Impressum";
import { Logo } from "./components/Logo";
import { Navbar } from "./components/Navbar";
import { SingleImagePage } from "./components/SingleImagePage";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
:root {
  --black: #292929;
  --black-darker: #212121;
  --light-blue: #0a799b;
  --white: white;
}

  html {
    scroll-behavior: smooth;
  }
  * {
    box-sizing: border-box;
    padding:0;
    margin:0;
  }

  body {
    overflow-x: hidden;
  }

  h1 {
    font-size: 3rem;
    color: var(--black);
    font-family: 'Neucha', cursive;
  }
  p {
    font-size: 1.2rem;
    line-height: 1.6;
  }
`;

export default function App() {
  return (
    <>
      <GlobalStyle />
      <Logo />
      <Navbar />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/illustration/:id" element={<SingleImagePage />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </>
  );
}
