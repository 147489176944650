import React from "react";
import styled from "styled-components";
import rb from "./images/rb.svg";
import pinterest from "./images/pinterest.svg";
import facebook from "./images/facebook.svg";

export const MediaMenu = () => {
  return (
    <StyledMediaMenu>
      <Icon href="https://www.redbubble.com/people/MariaElizaK/shop?anchor=profile&asc=u">
        <img src={rb} alt="rb" />
      </Icon>
      <Icon href="https://www.pinterest.ca/mariaelizakoulo/artwork-and-products-maria-eliza-kouloudi/">
        <img src={pinterest} alt="pinterest" />
      </Icon>
      <Icon href="https://www.facebook.com/MariaElizaKouloudiillustrations">
        <img src={facebook} alt="facebook" id="facebook" />
      </Icon>
    </StyledMediaMenu>
  );
};

const StyledMediaMenu = styled.section`
  color: var(--white);
  position: relative;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  display: flex;
  width: 100%;
`;

const Icon = styled.a`
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
  border: 1px var(--light-blue) solid;
  img {
    border-radius: 50%;
    width: 30px;
  }
`;
