import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { MediaMenu } from "./MediaMenu";
export const Footer = () => {
  return (
    <>
      <FooterStyle>
        <Title>
          <p>Maria Eliza Kouloudi</p>
        </Title>
        <Content>
          <StyledContact>
            <Email>Email: mariaelizakouloudi@gmail.com</Email>
            <Phone>Phone: +49 1514 5157767</Phone>
            <Impressum>
              <Link to="/impressum">Impressum / DGBR</Link>
            </Impressum>
          </StyledContact>
          <ButtonContainer>
            <Button href="https://www.redbubble.com/people/MariaElizaK/shop?anchor=profile&asc=u">
              Order Printings
            </Button>
            <MediaMenu />
          </ButtonContainer>
        </Content>
      </FooterStyle>
    </>
  );
};

const ButtonContainer = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
`;

const Button = styled.a`
  background-color: var(--light-blue);
  color: var(--white);
  padding: 5px 10px;
  border-radius: 10px;
  text-decoration: none;
  margin-bottom: 10px;
`;

const Email = styled.div`
  font-size: 0.8rem;
`;
const Phone = styled.div`
  margin-top: 15px;
  font-size: 0.8rem;
`;

const Impressum = styled(Phone)`
  & > * {
    color: white;
    text-decoration: none;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
  flex-wrap: wrap;
`;

const Title = styled.header`
  p {
    font-size: 1.5rem;
    font-weight: 500;
  }
`;

const StyledContact = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const FooterStyle = styled.section`
  background-color: var(--black);
  color: white;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-top: 1px rgba(0, 0, 0, 0.2) solid;
  padding: 1.5rem;
`;
