import React from "react";
import styled from "styled-components";

export const Contact = () => {
  return (
    <ContactContainer>
      <ContactStyle>
        <Title>Contact</Title>
        <Content>
          <Email>
            <p>Email: mariaelizakouloudi@gmail.com</p>
          </Email>
          <Phone>
            <p>Phone: +49 1514 5157767</p>
          </Phone>
        </Content>
      </ContactStyle>
    </ContactContainer>
  );
};

const ContactStyle = styled.div`
  padding: 2em;
  max-width: 65ch;
`;

const ContactContainer = styled.div`
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 300px);
`;
const Title = styled.div`
  font-size: 2rem;
  padding: 10px 0;
`;

const Content = styled.div`
  padding: 2em 0;
`;
const Email = styled.div`
  padding: 10px 0;
`;
const Phone = styled(Email)``;
