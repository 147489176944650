import React from "react";
import { Portfolio } from "./Portfolio";

export const Home = () => {
  return (
    <>
      <Portfolio />
    </>
  );
};
