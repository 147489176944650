import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router";
import { imagesCollection } from "./ImageLibrary";

export const Portfolio = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleLoadMore = () => {
    setPagination((prev) => prev + 12);
  };

  return (
    <PortfolioSection>
      <PortfolioStyle>
        {imagesCollection.slice(0, pagination + 12).map((image, index) => {
          return (
            <Images
              key={index}
              onClick={() => navigate(`/illustration/${image.index}`)}
            >
              <ImageContainerBg theme={image} key={image.url} />
              <p>{image.name}</p>
            </Images>
          );
        })}
      </PortfolioStyle>
      {pagination + 12 < imagesCollection.length && (
        <Button onClick={handleLoadMore}>
          Load more illustrations ({imagesCollection.length - pagination - 12})
        </Button>
      )}
    </PortfolioSection>
  );
};

const PortfolioSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  width: fit-content;
  padding: 20px 40px;
  text-transform: uppercase;
  background-color: var(--light-blue);
  color: white;
  border: 1px transparent solid;
  &:hover {
    background-color: white;
    color: var(--light-blue);
    border-color: var(--light-blue);
  }
`;

const animatePortfolio = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const PortfolioStyle = styled.div`
  width: 100%;
  padding: 8rem 2em;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  img {
    padding: 10px;
    overflow: hidden;
    width: 300px;
    height: 300px;
  }
  transform: translateY(100px);
  opacity: 0;
  animation: ${animatePortfolio} 1s forwards;
`;

const ImageContainerBg = styled.div`
  width: 300px;
  height: 300px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &::before {
    position: absolute;
    content: "";
    background-image: ${(props) => `url(../images/${props.theme.url})`};
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: 250ms all ease-in-out;
  }

  &:hover::before {
    transform: scale(1.1);
  }
`;

const Images = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
  max-width: 300px;
  p {
    color: var(--black);
    cursor: pointer;
    font-size: 1rem;
    padding: 5px 0;
    font-weight: 700;
    text-align: center;
  }
  &:hover {
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.2);
    p {
      color: black;
      font-weight: 800;
    }
  }
`;
