let id = 0;

export const imagesCloudinary = [
  {
    pictureId: ++id,
    name: "Panic attack",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246857/maria-eliza-kouloudi/20220115/1-PanicAttack_d8mzh7.jpg",
  },
  {
    pictureId: ++id,
    name: "Just the two of us",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246857/maria-eliza-kouloudi/20220115/2-JustthetwoofUs_csqlct.jpg",
  },
  {
    pictureId: ++id,
    name: "Morning challenge",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246855/maria-eliza-kouloudi/20220115/3-MorningChallenge_nccmzt.jpg",
  },
  {
    pictureId: ++id,
    name: "Nature will always win",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246860/maria-eliza-kouloudi/20220115/4-Naturewillalwayswin_lp2za5.jpg",
  },
  {
    pictureId: ++id,
    name: "Art Salon",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246855/maria-eliza-kouloudi/20220115/5-ArtSalon_ztguhr.jpg",
  },
  {
    pictureId: ++id,
    name: "Animal Lover",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246855/maria-eliza-kouloudi/20220115/6-AnimalLover_gf8kwb.jpg",
  },
  {
    pictureId: ++id,
    name: "Me versus the world",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246859/maria-eliza-kouloudi/20220115/7-MeversustheWorld_bqljis.jpg",
  },
  {
    pictureId: ++id,
    name: "Intimacy in the time of Quarantine",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246858/maria-eliza-kouloudi/20220115/8-IntimacyinthetimeofQuarantine_f6pmzg.jpg",
  },
  {
    pictureId: ++id,
    name: "Merry Christmas to all",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246859/maria-eliza-kouloudi/20220115/9-MerryChristmastoAll_nw8sqs.jpg",
  },
  {
    pictureId: ++id,
    name: "Feels like home",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246861/maria-eliza-kouloudi/20220115/10-FeelslikeHome_cqnayh.jpg",
  },
  {
    pictureId: ++id,
    name: "Serial killer",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246860/maria-eliza-kouloudi/20220115/11-SerialKiller_co6gos.jpg",
  },
  {
    pictureId: ++id,
    name: "Auto relato - Collaboration with Paulina Mariman - Relatos periféricos",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246858/maria-eliza-kouloudi/20220115/12-AutorelatoPortraitofPaulinaMariman_RelatosPerifericos_jcased.jpg",
  },
  {
    pictureId: ++id,
    name: "Motherhood",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246861/maria-eliza-kouloudi/20220115/13-Motherhood_ibmtji.jpg",
  },
  {
    pictureId: ++id,
    name: "Motherhood",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246861/maria-eliza-kouloudi/20220115/14-Motherhood_joxwuk.jpg",
  },
  {
    name: "Dialogue - The concert",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246861/maria-eliza-kouloudi/20220115/15-DialogueTheConcert_auftno.jpg",
  },
  {
    pictureId: ++id,
    name: "Dialogue - Romero and Juliet",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246860/maria-eliza-kouloudi/20220115/16-DialogueRomeoandJuliet_c63qqv.jpg",
  },
  {
    pictureId: ++id,
    name: "Talking head",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246861/maria-eliza-kouloudi/20220115/17-TalkingHead_rbpw80.jpg",
  },
  {
    name: "Talking head",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246860/maria-eliza-kouloudi/20220115/18-TalkingHead-1_w0ktfk.jpg",
  },
  {
    pictureId: ++id,
    name: "Talking head",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246864/maria-eliza-kouloudi/20220115/19-TalkingHead_eapwxx.jpg",
  },
  {
    pictureId: ++id,
    name: "Talking head",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246862/maria-eliza-kouloudi/20220115/20-TalkingHead_fc4ail.jpg",
  },
  {
    name: "Reading time",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246865/maria-eliza-kouloudi/20220115/21-ReadingTime_m0syqq.jpg",
  },
  {
    pictureId: ++id,
    name: "Shopping time",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246863/maria-eliza-kouloudi/20220115/22-ShoppingTime_h7kbql.jpg",
  },
  {
    pictureId: ++id,
    name: "Girls wanna have fun",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246863/maria-eliza-kouloudi/20220115/23-GirlswannahaveFun_wlf9gs.jpg",
  },
  {
    pictureId: ++id,
    name: "Tea party preparations",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246869/maria-eliza-kouloudi/20220115/24-TeaPartyPreparations_chhyto.jpg",
  },
  {
    pictureId: ++id,
    name: "Avocado",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246863/maria-eliza-kouloudi/20220115/25-Avocado_nbhhyy.jpg",
  },
  {
    pictureId: ++id,
    name: "Banana",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246864/maria-eliza-kouloudi/20220115/26-Banana_emj2pn.jpg",
  },
  {
    pictureId: ++id,
    name: "Zucchini",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246864/maria-eliza-kouloudi/20220115/27-Zucchini_rheaek.jpg",
  },
  {
    pictureId: ++id,
    name: "Pepper und aubergine",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246867/maria-eliza-kouloudi/20220115/28-PepperundObergine_scdybl.jpg",
  },
  {
    pictureId: ++id,
    name: "Stay calm",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246865/maria-eliza-kouloudi/20220115/29-StayCalm_h4gss0.jpg",
  },

  {
    pictureId: ++id,
    name: "Creating space",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246867/maria-eliza-kouloudi/20220115/30-CreatingSpace_urdu5t.jpg",
  },
  {
    pictureId: ++id,
    name: "Home",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246867/maria-eliza-kouloudi/20220115/31-Home_y9h65k.jpg",
  },
  {
    pictureId: ++id,
    name: "Moving on",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246869/maria-eliza-kouloudi/20220115/32-MovingOn_mqs3gv.jpg",
  },
  {
    pictureId: ++id,
    name: "Poster for the world day of the fight agains breast cancer",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246867/maria-eliza-kouloudi/20220115/33-PosterfortheWorldDayoftheFightagainstBreastCancer_dz64bh.jpg",
  },
  {
    pictureId: ++id,
    name: "Leaving paradise",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246866/maria-eliza-kouloudi/20220115/34-LeavingParadise_ahh9gz.jpg",
  },
  {
    pictureId: ++id,
    name: "Christmas tree",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246871/maria-eliza-kouloudi/20220115/35-ChristmasTree_zchbnn.jpg",
  },
  {
    pictureId: ++id,
    name: "Showman - The house edition",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246870/maria-eliza-kouloudi/20220115/36-ShowmanTheHouseEdition_oafves.jpg",
  },
  {
    pictureId: ++id,
    name: "Wedding invitation",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246870/maria-eliza-kouloudi/20220115/37-WeddingInvitation_vfb5cy.jpg",
  },
  {
    pictureId: ++id,
    name: "Little Jesus wants to play",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246870/maria-eliza-kouloudi/20220115/38-LittleJesuswantstoplay_c6tpkp.jpg",
  },
  {
    pictureId: ++id,
    name: "Sweet dreams",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246872/maria-eliza-kouloudi/20220115/39-SweetDreams_qnjedk.jpg",
  },
  {
    pictureId: ++id,
    name: "Between optimism and delusion",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246870/maria-eliza-kouloudi/20220115/40-BetweenOptimismandDelusion_h9vj6x.jpg",
  },
  {
    pictureId: ++id,
    name: "I love bread",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246872/maria-eliza-kouloudi/20220115/41-IloveBread_doxqjx.jpg",
  },
  {
    pictureId: ++id,
    name: "I could eat only schrimps",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246872/maria-eliza-kouloudi/20220115/42-IcouldeatonlySchrimps_egtosz.jpg",
  },
  {
    pictureId: ++id,
    name: "Commision for Ckoi Atacama Hotel Chile",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246872/maria-eliza-kouloudi/20220115/43-LlamasCommisionforCkoiAtacamaHotelSanPedrodeAtacamaChile_cjowuw.jpg",
  },
  {
    pictureId: ++id,
    name: "Forest",
    url: "https://res.cloudinary.com/matiasfacio/image/upload/v1642246873/maria-eliza-kouloudi/20220115/44-Forest_fhnnj1.jpg",
  },
];
