import React from "react";
import styled from "styled-components";

export const About = () => {
  return (
    <AboutContainer>
      <AboutStyle>
        <Title>About</Title>
        <Content>
          <p>
            Hi! I am an Illustrator and scene designer, originated from Greece
            and based in Berlin, Germany. <br />I create handmade illustrations
            by mixing various mediums, mainly acrylic, markers and watercolor,
            and performing digital editing. Space, color and narrative are
            playing an important role in my illustrations.
            <br /> I have a wide spectrum of themes, but I am particularly
            interested in human psychology, sexuallity and social awareness. I
            am inspired mostly by the reality around me and the art in all its
            forms. <br />
            If you are interested in an order or commision, looking up for a
            collaboration or just want to ask a question, please feel free to
            email to{" "}
            <a href="mailto:mariaelizakouloudi@gmail.com">
              mariaelizakouloudi@gmaill.com.
            </a>
            <br />
            I am looking forward to hearing from you!
            <br />
            <br />
            Maria Eliza
          </p>
        </Content>
      </AboutStyle>
    </AboutContainer>
  );
};

const AboutStyle = styled.div`
  padding: 2em;
  max-width: 65ch;
`;

const AboutContainer = styled.div`
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
`;
const Title = styled.div`
  font-size: 2rem;
  padding: 10px 0;
`;

const Content = styled.div`
  padding: 2em 0;
`;
