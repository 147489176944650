import React from "react";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import menuOpen from "../utils/images/menu-24px.svg";
import closeOpen from "../utils/images/close-24px.svg";
import { MediaMenu } from "./MediaMenu";

export const Navbar = () => {
  const [responsiveMenuVisibility, setResponsiveMenuVisibility] =
    React.useState(false);

  const handleClick = () => {
    setResponsiveMenuVisibility(!responsiveMenuVisibility);
  };

  const handleClickOnMenuItem = () => {
    if (responsiveMenuVisibility) {
      setResponsiveMenuVisibility(false);
    }
  };

  return (
    <>
      <Close onClick={handleClick}>
        {responsiveMenuVisibility ? (
          <img src={closeOpen} alt="menu close" height="40px" />
        ) : (
          <img src={menuOpen} alt="menu open" height="40px" />
        )}
        menu
      </Close>
      <StyledNav isVisible={responsiveMenuVisibility}>
        <NavbarStyle>
          <ul>
            <li>
              <Link to="/" onClick={handleClickOnMenuItem}>
                Illustrations
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={handleClickOnMenuItem}>
                About
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={handleClickOnMenuItem}>
                Contact
              </Link>
            </li>
          </ul>
          <MediaMenu />
        </NavbarStyle>
      </StyledNav>
    </>
  );
};

const animateSubtitle = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-100%)
  }
  100% {
    opacity: 1;
    transform: translateY(0);

  }
`;

const Close = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 0.8rem;
  font-weight: 700;
  display: none;
  z-index: 100;
  background-color: var(--light-blue);
  padding: 12px 20px;
  border-radius: 50%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  color: var(--white);
  @media screen and (max-width: 740px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const StyledNav = styled.div`
  position: fixed;
  height: 75px;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: white;
  z-index: 100;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-end;
  padding: 10px 30px;
  align-items: center;
  opacity: 0;
  transform: translateY(-100%);
  animation: ${animateSubtitle} 1s forwards;
  z-index: 99;
  @media screen and (max-width: 740px) {
    flex-direction: column;
    position: fixed;
    inset: 0;
    justify-content: center;
    padding: 0px 0px;
    ${({ isVisible }) => (isVisible ? `display: block` : `display:none`)}
  }
`;

const NavbarStyle = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: white;
  padding-right: 20px;
  ul {
    display: flex;
    list-style: none;
    margin-right: 20px;
    a {
      position: relative;
      margin: 10px 10px;
      text-decoration: none;
      font-size: 1.1rem;
      font-weight: 700;
      color: var(--black);
      border: 2px transparent solid;
      padding: 0px 0px;
      text-transform: uppercase;
      font-family: "Neucha", cursive;
    }
    @media screen and (max-width: 740px) {
      flex-direction: column;
      margin-top: 50px;
      height: 100vh;
      justify-content: center;

      li {
        margin-bottom: 40px;
        a {
          font-size: clamp(2rem, 3vw, 3rem);
        }
      }
    }
  }
  @media screen and (max-width: 740px) {
    background-color: white;
  }
`;
