import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { imagesCollection } from "./ImageLibrary";
import { imagesCloudinary } from "../utils/imagesCloudinary";

export const SingleImagePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const imageSearch = imagesCloudinary[id - 1];

  const imageSearchCollection = imagesCollection[id - 1];
  const relatedImages = [];
  if (imageSearchCollection.recommended) {
    relatedImages.push(
      imagesCollection[imageSearchCollection.recommended[0] - 1]
    );
    relatedImages.push(
      imagesCollection[imageSearchCollection.recommended[1] - 1]
    );
    relatedImages.push(
      imagesCollection[imageSearchCollection.recommended[2] - 1]
    );
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <SingleImagePageStyle>
      <h2>{imageSearch.name}</h2>

      <MainImageContainer>
        <MainImage
          src={process.env.PUBLIC_URL + `${imageSearch.url}`}
          alt={imageSearch.name}
        />
      </MainImageContainer>

      {imageSearchCollection.recommended && (
        <CarrouselContainer>
          <h2>See also:</h2>
          <Carrousel>
            {relatedImages.map((image, index) => {
              return (
                <Images key={index}>
                  <ImageContainerBg
                    image={image}
                    onClick={() =>
                      navigate(
                        `/illustration/${imageSearchCollection.recommended[index]}`
                      )
                    }
                  />
                  <p>{image.name}</p>
                </Images>
              );
            })}
          </Carrousel>
        </CarrouselContainer>
      )}
    </SingleImagePageStyle>
  );
};

const MainImageContainer = styled.div`
  min-height: 70vh;
  max-width: calc(100% - 2vw);
  overflow: auto;
`;

const MainImage = styled.img`
  height: 70vh;
  width: 100%;
  object-fit: contain;
  margin-top: 50px;
`;

const SingleImagePageStyle = styled.div`
  margin-top: 8rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Carrousel = styled.div`
  margin-top: 20px;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(1fr, auto);
  overflow-x: auto;
  gap: 20px;
  @media screen and (max-width: 990px) {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(1fr, auto);
  }
`;
const CarrouselContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Images = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImageContainerBg = styled.div`
  width: 250px;
  height: 250px;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  &::before {
    position: absolute;
    content: "";
    background-image: ${({ image }) => `url(../images/${image.url})`};
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: 250ms all ease-in-out;
  }

  &:hover::before {
    transform: scale(1.1);
  }

  @media screen and (max-width: 990px) {
    width: 250px;
    height: 250px;
  }
`;
