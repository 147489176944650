let id = 0;

export const imagesCollection = [
  {
    index: ++id,
    url: "1-PanicAttack.jpg",
    name: "Panic Attack",
    recommended: [29, 32, 10],
  },
  {
    index: ++id,
    url: "3-Justthetwoofus.jpg",
    name: "Just The Two Of Us",
    recommended: [8, 34, 11],
  },
  {
    index: ++id,
    url: "2-MorningChallenge.jpg",
    name: "Morning Challenge",
    recommended: [7, 31, 1],
  },
  {
    index: ++id,
    url: "4-Naturewillalwayswin.jpg",
    name: "Nature will always win",
    recommended: [6, 24, 32],
  },
  {
    index: ++id,
    url: "5-ArtSalon.jpg",
    name: "Art Salon",
    recommended: [30, 15, 11],
  },
  {
    index: ++id,
    url: "6-AnimalLover.jpg",
    name: "Animal Lover",
    recommended: [1, 4, 11],
  },
  {
    index: ++id,
    url: "7-MeVersustheWorld.jpg",
    name: "Me Versus The World",
    recommended: [10, 14, 40],
  },
  {
    index: ++id,
    url: "8-IntimacyinthetimeofQuarantine.jpg",
    name: "Intimacy in the Time of Quarantine",
    recommended: [2, 29, 31],
  },
  {
    index: ++id,
    url: "23-MerryChristmastoAll.jpg",
    name: "Merry Christmas to all",
    recommended: [33, 36, 34],
  },
  {
    index: ++id,
    url: "9-Feelslikehome.jpg",
    name: "Feels like home",
    recommended: [13, 4, 12],
  },
  {
    index: ++id,
    url: "10-SerialKiller.jpg",
    name: "Serial Killer",
    recommended: [1, 29, 34],
  },
  {
    index: ++id,
    url: "31-Auto-relatocollaborationwithPaulinaMariman-relatosperifericos.jpg",
    name: "Auto Relato - Relatos Periféricos",
    recommended: [3, 20, 44],
  },
  {
    index: ++id,
    name: "Motherhood",
    url: "13-Motherhood.jpeg",
    recommended: [14, 38, 36],
  },
  {
    index: ++id,
    name: "Motherhood",
    url: "14-Motherhood.jpeg",
    recommended: [13, 40, 35],
  },
  {
    index: ++id,
    url: "17-Dialogue-TheConcert.jpg",
    name: "Dialogue: The Concert",
    recommended: [16, 19, 5],
  },
  {
    index: ++id,
    url: "20-Dialogue-RomeoandJuliet.jpg",
    name: "Dialogue: Romeo and Juliet",
    recommended: [15, 18, 8],
  },
  {
    index: ++id,
    url: "16-TalkingHead.jpg",
    name: "Talking Head",
    recommended: [19, 20, 18],
  },
  {
    index: ++id,
    url: "18-TalkingHead-2.jpg",
    name: "Talking Head",
    recommended: [19, 17, 20],
  },
  {
    index: ++id,
    url: "19-TalkingHead-3.jpg",
    name: "Talking Head",
    recommended: [17, 18, 15],
  },
  {
    index: ++id,
    url: "21-TalkingHead-4.jpg",
    name: "Talking Head",
    recommended: [16, 18, 19],
  },

  {
    index: ++id,
    url: "11-ReadingTime.jpg",
    name: "Reading Time",
    recommended: [24, 22, 23],
  },
  {
    index: ++id,
    url: "13-ShoppingTime.jpg",
    name: "Shopping time",
    recommended: [21, 23, 25],
  },
  {
    index: ++id,
    url: "14-Girlswannahavefun.jpg",
    name: "Girls wanna have fun",
    recommended: [24, 21, 26],
  },
  {
    index: ++id,
    url: "15-TeaPartyPreparations.jpg",
    name: "Tea party Preparations",
    recommended: [22, 23, 27],
  },
  {
    index: ++id,
    url: "25-Avocado.jpg",
    name: "Avocado",
    recommended: [26, 28, 33],
  },
  {
    index: ++id,
    url: "28-Banana.jpg",
    name: "Banana",
    recommended: [25, 27, 28],
  },
  {
    index: ++id,
    url: "27-Zucchini.jpg",
    name: "Zucchini",
    recommended: [25, 26, 41],
  },
  {
    index: ++id,
    name: "Pepper und aubergine",
    url: "30-PepperundObergine.jpg",
    recommended: [25, 27, 42],
  },
  {
    index: ++id,
    name: "Stay calm",
    url: "29-StayCalm.jpeg",
    recommended: [3, 7, 9],
  },
  {
    index: ++id,
    url: "29-CreatingSpace.jpg",
    name: "Creating Space",
    recommended: [1, 6, 10],
  },
  { index: ++id, url: "26-Home.jpg", name: "Home", recommended: [4, 8, 36] },
  {
    index: ++id,
    url: "12-Moving-On.jpg",
    name: "Moving on",
    recommended: [2, 5, 3],
  },
  {
    index: ++id,
    url: "24-PosterfortheWorldDayofthefightagainstbreastcancer.jpg",
    name: "Fight against breast Cancer",
    recommended: [34, 13, 14],
  },
  {
    index: ++id,
    url: "22-LeavingParadise.jpg",
    name: "Leaving paradise",
    recommended: [13, 12, 33],
  },
  {
    index: ++id,
    url: "35-ChristmasTree.jpg",
    name: "Christmas Tree",
    recommended: [9, 36, 39],
  },
  {
    index: ++id,
    url: "32-Showman-TheHouseEdition.jpg",
    name: "Showman: The House Edition",
    recommended: [35, 14, 38],
  },
  {
    index: ++id,
    url: "33-WeddingInvitation-Commision.jpg",
    name: "Wedding Invitation",
    recommended: [43, 12, 20],
  },
  {
    index: ++id,
    name: "Little Jesus wants to play",
    url: "38.LittleJesuswantstoplay.jpeg",
    recommended: [15, 37, 44],
  },
  {
    index: ++id,
    name: "Sweet dreams",
    url: "39-Sweetdreams.jpeg",
    recommended: [42, 19, 24],
  },
  {
    index: ++id,
    url: "34-ThefineLinebetweenOptimismandDelusion.jpg",
    name: "The fine line between optimism and delusion",
    recommended: [7, 10, 13],
  },
  {
    index: ++id,
    url: "36-IloveBread.jpg",
    name: "I love bread",
    recommended: [27, 28, 18],
  },
  {
    index: ++id,
    url: "37-IcouldeatonlySchrimps.jpg",
    name: "I could eat only schrimps",
    recommended: [6, 26, 25],
  },
  {
    index: ++id,
    url: "38-CommisionforCkoiAtacamaHotelSanPedrodeAtacamaChile.jpg",
    name: "Comission: Ckoi Atacama Hotel",
    recommended: [14, 37, 44],
  },
  {
    index: ++id,
    url: "39-Forest.jpg",
    name: "Forest",
    recommended: [17, 33, 9],
  },
];
