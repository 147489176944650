import React from "react";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router";
export const Logo = () => {
  const navigate = useNavigate();
  return (
    <LogoStyle onClick={() => navigate("/")}>Maria Eliza Kouloudi</LogoStyle>
  );
};

const animateSubtitle = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-100%)
  }
  100% {
    opacity: 1;
    transform: translateX(0);

  }
`;

const LogoStyle = styled.div`
  font-size: 2rem;
  font-weight: 500;
  color: var(--black);
  background-color: white;
  z-index: 100;
  font-family: "Neucha", cursive;
  cursor: pointer;
  opacity: 0;
  transform: translateX(-100%);
  animation: ${animateSubtitle} 1s forwards;
  animation-delay: 1s;
  position: fixed;
  top: 20px;
  left: 20px;
  @media screen and (max-width: 640px) {
    font-size: 2rem;
    padding: 20px;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    background-color: var(--light-blue);
    color: var(--white);
  }
`;
