import React from "react";
import styled from "styled-components";

export const Impressum = () => {
  return (
    <>
      <StyledImpressum id="impressum">
        <div>
          <h2>Impressum & Datenschutzerklärung</h2>
          <br />
          <br />
          <br />
          <p>
            ANGABEN GEMÄSS § 5 TMG
            <br />
            Maria Eliza Kouloudi
            <br />
            <br />
            Anschrift: Friedelstrasse 61, 12047 Berlin
            <br />
            <br />
            KONTAKT:
            <br />
            Email-Adresse: mariaelizakouloudi@gmaill.com
            <br />
            <br />
            Telefonnummer: +49 151 4515 7767
            <br />
            <br />
            Id.-Nr.: 87 / 214 601 359
            <br />
            <br />
            STREITSCHLICHTUNG
            <br />
            Die Europäische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit:
            https://ec.europa.eu/consumers/odr. Unsere E-Mail-Adresse finden Sie
            oben im Impressum.
            <br />
            <br />
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
            <br />
            <br />
            1. DATENSCHUTZ AUF EINEN BLICK
            <br />
            ALLGEMEINE HINWEISE
            <br />
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website
            besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
            aufgeführten Datenschutzerklärung.
            <br />
            <br />
            ANALYSE-TOOLS UND TOOLS VON DRITTANBIETERN
            <br />
            Es gibt weder Analyse-Tool noch Tools von Drittanbietern.
          </p>

          <p>
            2. ALLGEMEINE HINWEISE UND PFLICHTINFORMATIONEN
            <br />
            DATENSCHUTZ
            <br />
            Ich sammle keine Datei von der Benutzern dieser Website.
            <br />
            <br />
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich.
            <br />
            <br />
            HINWEIS ZUR VERANTWORTLICHEN STELLE
            <br />
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website ist:
            <br />
            <br />
            Matias Facio
            <br />
            Stargarderstrasse 74,
            <br />
            10437, Berlin
            <br />
            <br />
            Telefon: 01774946117
            <br />
            E-Mail: matiaspersonal@gmail.com
            <br />
            <br />
            Verantwortliche Stelle ist die natürliche oder juristische Person,
            die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten (z.B. Namen,
            E-Mail-Adressen o. Ä.) entscheidet.
            <br />
            <br />
            SSL- BZW. TLS-VERSCHLÜSSELUNG
            <br />
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
            oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
            SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
            erkennen Sie daran, dass die Adresszeile des Browsers von “http://”
            auf “https://” wechselt und an dem Schloss-Symbol in Ihrer
            Browserzeile.
            <br />
            <br />
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
            Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
            werden.
            <br />
            <br />
            3. PLUGINS UND TOOLS
            <br />
            WEB FONTS
            <br />
            Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
            genannte Web Fonts, die von Drittanbietern bereitgestellt werden.
            Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in
            ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
            <br />
            <br />
            Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu
            den Servern von Drittanbietern aufnehmen. Hierdurch erlangen diese
            Kenntnis darüber, dass über Ihre IP-Adresse unsere Website
            aufgerufen wurde. Die Nutzung von Web Fonts erfolgt im Interesse
            einer einheitlichen und ansprechenden Darstellung unserer
            Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von
            Art. 6 Abs. 1 lit. f DSGVO dar.
            <br />
            <br />
            Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
            Standardschrift von Ihrem Computer genutzt.
          </p>
        </div>
      </StyledImpressum>
    </>
  );
};

const StyledImpressum = styled.div`
  width: 80%;
  max-width: 800px;
  margin: 100px auto;
`;
